<template>
  <!-- detail delivery orders -->
  <div class="w-full">
    <vs-table
      :style="{ width: '100%' }"
      :sst="false"
      :data="dataViews"
      :total="dataViews.length"
      :searchable="false"
    >
      <template slot="thead">
        <vs-th v-if="!isNew">Delivery Plan Code</vs-th>
        <vs-th>Delivery Order Code</vs-th>
        <vs-th>Customer Data</vs-th>
        <vs-th v-if="!isNew">Request Delivery Date</vs-th>
        <vs-th v-if="!isNew">Weight</vs-th>
        <vs-th v-if="!isNew">Volume</vs-th>
        <vs-th>Warehouse</vs-th>
        <vs-th>Zone</vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr :data="tk" :key="indextr" v-for="(tk, indextr) in data">
          <vs-td v-if="!isNew">
            {{ tk.delivery_plan_code }}
          </vs-td>
          <vs-td>
            {{ tk.delivery_order_code }}
          </vs-td>
          <vs-td>
            <ul>
              <li>
                <strong>Customer Code:</strong>
                {{ tk.customer_code }}
              </li>
              <li>
                <strong>Customer Name:</strong>
                {{ tk.customer_name }}
              </li>
              <li>
                <strong>Customer Address:</strong>
                {{ tk.customer_address }}
              </li>
            </ul>
          </vs-td>
          <vs-td v-if="!isNew">
            {{ tk.request_delivery_date }}
          </vs-td>

          <vs-td v-if="!isNew">
            {{ tk.weight }}
          </vs-td>
          <vs-td v-if="!isNew">
            {{ tk.volume }}
          </vs-td>
          <vs-td>
            {{ tk.warehouse_name }}
          </vs-td>
          <vs-td>
            {{ tk.zone_name }}
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
  </div>
</template>

<script>
export default {
  props: {
    dataViews: {
      type: Array,
      default: () => [],
    },
    isNew: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style>
.vl {
  border-left: 6px solid black;
  height: 100%;
}
</style>
