<template>
  <!-- detail delivery orders -->
  <div class="w-full">
    <vs-table
      :style="{ width: '100%' }"
      :sst="false"
      :data="dataViews"
      :total="dataViews.length"
      :searchable="false"
    >
      <template slot="thead">
        <vs-th>No</vs-th>
        <vs-th>Code</vs-th>
        <vs-th>Name</vs-th>
        <vs-th>Unit</vs-th>
        <vs-th>BATCH</vs-th>
        <vs-th>QTY</vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr :data="tk" :key="indextr" v-for="(tk, indextr) in data">
          <vs-td>
            {{ indextr + 1 }}
          </vs-td>
          <vs-td>
            {{ tk.item_code }}
          </vs-td>
          <vs-td>
            {{ tk.item_name }}
          </vs-td>
          <vs-td>
            {{ tk.item_unit }}
          </vs-td>
          <vs-td>
            {{ tk.batch }}
          </vs-td>
          <vs-td>
            {{ tk.quantity }}
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
  </div>
</template>

<script>
export default {
  props: {
    dataViews: {
      type: Array,
      default: () => [],
    },
    isNew: {
      type: Boolean,
      default: false,
    },
  },
  computed: {},
};
</script>
<style>
.vl {
  border-left: 6px solid black;
  height: 100%;
}
</style>
